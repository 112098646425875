import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { Button } from '@nebo-team-dev/vobaza.ui.button';
import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';
import { SmallMobileNavigation } from './SmallMobileNavigation';

import { setPopularCategories } from 'src/store/categories';
import type { MenuProps } from '../types';
import { toggleModal } from 'src/store/auth';
import ProfileMenu from 'components/Profile/Menu';
import { PopularCategories } from 'app/categories';
import { useDispatch } from 'shared/lib/hooks/useDispatch';
import styles from './styles.module.scss';
import { api } from 'global/api';
import { RootState } from 'src/store';
import Preloader from 'shared/ui/Preloader';

export const HeaderSmallSizeMobileMenu = ({ close, isOpen }: MenuProps) => {
  const [isProfileMenuOpen, setIsProfileOpen] = useState(false);
  const [isRegisterButtonVisible, setRegisterButtonVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isAuth = Boolean(Cookies.get('token'));
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: RootState) => state.auth.isModalOpen);
  const categories = useSelector((state: RootState) => state.categories.popularCategories);
  const toggleProfileMenu = () => {
    setIsProfileOpen(!isProfileMenuOpen);
  };

  const profileClickHandler = () => {
    if (isAuth) {
      toggleProfileMenu();
    } else {
      dispatch(toggleModal());
    }
  };
  const onClose = () => {
    close();
  };

  useEffect(() => {
    if (Boolean(categories.length)) return;

    const getCategories = async () => {
      setLoading(true);
      try {
        const res = await api.getPopularCategories();
        dispatch(setPopularCategories(res.data.data));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    isOpen && getCategories();
  }, [isOpen]);

  useEffect(() => {
    setRegisterButtonVisible(isOpen && !isProfileMenuOpen && !isModalOpen && !isAuth);
  }, [isOpen, isProfileMenuOpen, isModalOpen, isAuth]);

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.mobileMenu__header}>
        <Link href="/">
          <Icon name="VobazaLogo" />
        </Link>
        <button onClick={onClose}>
          <Icon name="Cross" />
        </button>
      </div>
      <SmallMobileNavigation onClose={onClose} profileClickHandler={profileClickHandler} />
      <h3 className={styles.mobileMenu__categoriesTitle}>Популярные категории</h3>
      {!isLoading ? (
        <PopularCategories
          onCategoryClick={onClose}
          className={`${!isAuth ? styles.mobileMenu__categories : ''}`}
          type="list"
          categories={categories}
        />
      ) : (
        <Preloader />
      )}
      {isRegisterButtonVisible &&
        createPortal(
          <div className={styles.mobileMenu__register}>
            <Button onClick={profileClickHandler} size="big" text="Вход | Регистрация" />
          </div>,
          document.body
        )}
      <ProfileMenu isOpen={isProfileMenuOpen} close={toggleProfileMenu} />
    </div>
  );
};
