import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  openPhoneCallModal: () => void;
};

const FooterContacts: FC<Props> = ({ openPhoneCallModal }) => {
  return (
    <div className={`${styles.contactsBlock} container`}>
      <div className={styles.contactsItem}>
        <div className={styles.contactsTitle}>Есть вопросы? Звоните:</div>
        <a href="tel:+74951725622" onClick={openPhoneCallModal}>
          {' '}
          8 (495) 172 56 22{' '}
        </a>
        <div className={styles.contactsWorkTime}>работает с&nbsp;9:00 до&nbsp;18:00, пн.— пт.</div>
      </div>
      <div className={styles.contactsItem}>
        <div className={styles.contactsTitle}>Электронная почта</div>
        <a href="mailto:info@vobaza.ru" className={styles.contactsEmail}>
          info@vobaza.ru
        </a>
      </div>
    </div>
  );
};

export default FooterContacts;
